fiab.customMusicPlayer = (function($){
  "use strict";

    function init(){
        var $cmp = $('.custom-music-player'),
        $open = $cmp.find('.open'),
        $close = $cmp.find('.close');

        $($open).on('click',function(){
            $cmp.toggleClass('-is-open');
        })
        $($close).on('click',function(){
            $cmp.toggleClass('-is-open');
        })
    }

    return {
        init:init
    }

})(jQuery);
