fiab.sliders = (function($){
  "use strict";

  function init(){

          // $('.video-slider').append(
          //     '<div>'+
          //     '<div class="videoWrapper">'+
          //     '<iframe width="854" height="480" src="https://www.youtube.com/embed/T3r3QfZ5fxg?rel=0" frameborder="0" allowfullscreen></iframe>'+
          //     '</div>'+
          //     '</div>'+
          //     '<div>'+
          //     '<div class="videoWrapper">'+
          //     '<iframe width="854" height="480" src="https://www.youtube.com/embed/NpJz8xMEUCI?rel=0" frameborder="0" allowfullscreen></iframe>'+
          //     '</div>'+
          //     '</div>'+
          //     '<div>'+
          //     '<div class="videoWrapper">'+
          //     // '	<iframe width="854" height="480" src="https://www.youtube.com/embed/NpJz8xMEUCI" frameborder="0" allowfullscreen></iframe>'+
          //     '<iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fgypsyvendetta%2Fvideos%2F1334601513295464%2F&width=500&show_text=false&height=280&appId" width="500" height="280" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allowFullScreen="true"></iframe>'+
          //     '</div>'+
          //     '</div>'
          // );

          // $('.video-slider').slick({
          //   dots: true,
          //   infinite: true,
          //   speed: 300,
          //   slidesToShow: 1,
          //   slidesToScroll: 1
          // });
          
          //   $(document).ready(function(){
          //     $('.image-slider').slick({
          //         dots: true,
          //         infinite: true,
          //         speed: 300,
          //         slidesToShow: 1,
          //         slidesToScroll: 1
          //     });
          // });


      // $(document).ready(function(){
      //     $('.shows-slider').slick({
      //         dots: true,
      //         infinite: true,
      //         speed: 300,
      //         slidesToShow: 1,
      //         slidesToScroll: 1,

      //     });
      // });

      $(document).ready(function(){
        $('.press-slider').slick({
            dots: true,
            infinite: true,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,

        });
    });

  }

  return {
    init:init
  }

})(jQuery);
