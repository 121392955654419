fiab.tour = (function($){
    "use strict";
  
    function init(){
        if( $('#tour-calendar').length > 0 ){
            fetchConcerts();
        }
    }
    
    function fetchConcerts(){
        $.ajax({
            url: "https://www.googleapis.com/drive/v2/files/1LekEdBF6UNIzF52uX47W91m13WtXU-n8uDONM7pH-48/export?mimeType=text%2Fcsv&key=AIzaSyC8kL0lZgsBiZl5dc8EKuXCXzGtSU7MgVk",
            success : function (result){
                var calendarData = CSVToArray(result);
                var line = calendarData[0];
                var date = new Date(line[0]);
            calendarData.splice(0, 1);
                createTourCalendar(date, calendarData);
            }
        });
    }

    function CSVToArray( strData, strDelimiter ){
        // Check to see if the delimiter is defined. If not,
        // then default to comma.
        strDelimiter = (strDelimiter || ",");
        // Create a regular expression to parse the CSV values.
        var objPattern = new RegExp(
            (
                // Delimiters.
                "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
                // Quoted fields.
                "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
                // Standard fields.
                "([^\"\\" + strDelimiter + "\\r\\n]*))"
            ),
            "gi"
            );
        // Create an array to hold our data. Give the array
        // a default empty first row.
        var arrData = [[]];
        // Create an array to hold our individual pattern
        // matching groups.
        var arrMatches = null;
        // Keep looping over the regular expression matches
        // until we can no longer find a match.
        while (arrMatches = objPattern.exec( strData )){
            // Get the delimiter that was found.
            var strMatchedDelimiter = arrMatches[ 1 ];
            // Check to see if the given delimiter has a length
            // (is not the start of string) and if it matches
            // field delimiter. If id does not, then we know
            // that this delimiter is a row delimiter.
            if (
                strMatchedDelimiter.length &&
                (strMatchedDelimiter != strDelimiter)
                ){
                // Since we have reached a new row of data,
                // add an empty row to our data array.
                arrData.push( [] );
            }
            // Now that we have our delimiter out of the way,
            // let's check to see which kind of value we
            // captured (quoted or unquoted).
            if (arrMatches[ 2 ]){
                // We found a quoted value. When we capture
                // this value, unescape any double quotes.
                var strMatchedValue = arrMatches[ 2 ].replace(
                    new RegExp( "\"\"", "g" ),
                    "\""
                    );
            } else {
                // We found a non-quoted value.
                var strMatchedValue = arrMatches[ 3 ];
            }
            // Now that we have our value string, let's add
            // it to the data array.
            arrData[ arrData.length - 1 ].push( strMatchedValue );
        }
        // Return the parsed data.
        return( arrData );
    }



    function createTourCalendar(currentDate, calendarData){
        if (isNaN(currentDate.valueOf())){
            // Don't render calendar, if we don't know the date
            return;
        }
        
        var ys = currentDate.getFullYear().toString();
        
        document.getElementById("tour_header").innerHTML = "TOUR CALENDAR ";
        // if (currentDate.getMonth() == 11){
        //     document.getElementById("tour_header").innerHTML = "TOUR CALENDAR " + ys + " / " + ys+1;
        // } else {
        //     document.getElementById("tour_header").innerHTML = "TOUR CALENDAR " + ys;
        // }
        
        var tourContainer = document.getElementById("tour-box");
    
        var i;
        var flag = false;
    
        for (i = 0; i < calendarData.length; i++){
        // Render each calendar entry from a line in the document
            var line = calendarData[i];
            var date = new Date(line[0]);
    
            if(isNaN(date.valueOf())){
                continue;
            }
        
            function infoString(address){
                if(address.length) {
                    return "<span>info</span></a>";
                } else {
                    return "";
                }
            }
           
            if (date > currentDate){
                var myDiv = document.createElement("DIV");
                myDiv.setAttribute("class", "tour-item");

                if(line[3].length > 0){
                    myDiv.innerHTML = "<span class='address'>"+date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()  + "</span><a target='_blank' href=" + line[3] + "><b> " + line[1] + "</b> " + line[2] + infoString(line[3]) + "</a>";
                }
                else{
                    myDiv.innerHTML = "<span class='address'>"+date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()  + "</span><b> " + line[1] + "</b> " + line[2] + infoString(line[3]);
                }
                tourContainer.appendChild(myDiv);
            
                flag = true;
            }
        }
    
        if(flag){
            var tourButton = document.getElementById("tour-button");
            if(tourButton){
                tourButton.style.display = "inline-block";
            }
    
            var tourCalendar = document.getElementById("tour-calendar");
            if(tourCalendar){
                tourCalendar.style.display = "block";
            }
        }
    }

    return {
        init:init
    }
  
  })(jQuery);
  